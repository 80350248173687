import React from 'react'
import { Mission } from './Mission'
import { Value } from './Value'
import { Vision } from './Vision'
import { container, groupPhoto, recruitCake } from './MissionSection.module.scss'
import { RecruitButton } from './RecruitButton'
import { Title } from '../../ui/Title'

export const MissionSection = () => {
	return (
		<div className={container}>
			<Title title="ミッション" titleSub="MISSION" tag="h1" horizontal={false} className="flex-col items-start" />
			<Mission />
			<Vision />
			<Value />
			<div className={groupPhoto} />
			<a
				href="https://www.notion.so/Lisa-Technologies-Job-Board-f58a33f35de9473bb70c0fd49cdafd8e"
				target="_blank"
				rel="noopener noreferrer"
				className="absolute right-8 lg:right-0 -bottom-28 z-10"
			>
				<RecruitButton className={recruitCake} />
			</a>
		</div>
	)
}
