import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import {
	visionBg,
	visionBgSection,
	vidionMainBg,
	visionSection,
	visionSphere,
	visionSphereLine,
} from './Vision.module.scss'

export const Vision = () => {
	const [isDisplayed, setIsDisplayed] = useState(false)

	useEffect(() => {
		const target = document.getElementById('vision-position')
		const currentPosition = target.getBoundingClientRect().y

		function onScroll() {
			const position = target.getBoundingClientRect().y

			if (position < target.offsetTop - 100 && position > -50) {
				setIsDisplayed(true)
			}
		}

		if (currentPosition < target.offsetTop - 100 && currentPosition > -50) {
			setIsDisplayed(true)
		}
		document.addEventListener('scroll', onScroll)

		return () => {
			document.removeEventListener('scroll', onScroll)
		}
	}, [])

	return (
		<div className="flex flex-col lg:flex-row justify-between mt-20 w-full">
			<div className={classnames(visionSection, 'sm:pt-11')}>
				<div
					className={classnames(
						visionSphere,
						isDisplayed ? visionSphereLine : '',
						'flex relative justify-center items-center mr-auto bg-blue rounded-circle'
					)}
					id="vision-position"
				>
					<span className="text-base text-secondary">VISION</span>
				</div>
				<h2 className="mt-8 text-2xl lg:text-4xl font-bold">フードデリバリーによる</h2>
				<h2 className="mt-2 text-2xl lg:text-4xl font-bold">テクノロジーを通じて</h2>
				<h2 className="mt-2 text-2xl lg:text-4xl font-bold">日本の外食を世界へ</h2>
				<p className="mt-2 text-sm text-blue">Food delivery leads Japanese cuisine to the world</p>
				<p className="mt-8 text-sm leading-8">
					外食産業はDXによって、これまでにない大きなイノベーションが起きようとしています。COVID-19により、数多くの飲食店が苦境に立たされると同時にその変革の速度は急速に上がりました。
					フードデリバリーはこの変革の第一歩になると考えています。
					<br />
					私たちは日本の飲食店は世界一だと誇りを持っており、この変革に対して共に伴走し、日本の外食産業を世界へ展開する事を目指します。
				</p>
			</div>
			<div className={visionBgSection}>
				<div className={classnames(vidionMainBg, 'bg-center bg-no-repeat bg-contain rounded-l')} />
				<div className={classnames(visionBg, 'mt-16 bg-center bg-no-repeat bg-cover rounded-l')} />
			</div>
		</div>
	)
}
