import * as React from 'react'
import { Layout } from '../components/common/Layout'
import { SEO } from '../components/common/SEO'
import { MissionSection } from '../components/domains/mission'

const MissionPage = () => {
	return (
		<Layout location={[{ name: 'ミッション', path: '/mission' }]}>
			<SEO title="ミッション" description="" />
			<MissionSection />
		</Layout>
	)
}

export default MissionPage
