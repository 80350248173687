import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { valueFlame, valueSphere, valueSphereLine } from './Value.module.scss'

const valueData = [
	{
		name: 'リスペクト',
		sub: 'RESPECT',
		description: 'ミッションに共感し集まった仲間を尊重し信頼しよう。より良い相乗効果を生み出すチームにしよう。',
	},
	{
		name: 'カスタマードリブン',
		sub: 'CUSTOMER DRIVEN',
		description: 'ユーザーを深く理解し、ユーザーに寄り添い、本質的な課題を解決することで感動を創ろう。',
	},
	{
		name: 'アジリティ',
		sub: 'AGILITY',
		description:
			'不確実性の高い環境において、適切な判断とスピード感をもったアウトプットを大事にしていこう。それがチームを加速させ、社会の変革も加速する。',
	},
	{
		name: 'オープンシェア',
		sub: 'OPEN SHARE',
		description:
			'情報をどんどんオープンにし、フラットなチームをつくろう。フラットなチームで本質的なイシューに取り組もう。',
	},
	{
		name: 'ジブンゴト',
		sub: 'OWNERSHIP',
		description:
			'今から始めよう。自らが主役になって、主体的に社会の課題に取り組もう。そして、チームで喜びを分かち合おう。',
	},
	{
		name: 'マエダオレ',
		sub: 'CHALLENGE',
		description: '大胆にチャレンジしよう。何もしないことより、チャレンジして失敗した方がかっこいい。',
	},
]

export const Value = () => {
	const [isDisplayed, setIsDisplayed] = useState(false)

	useEffect(() => {
		const target = document.getElementById('value-position')
		const currentPosition = target.getBoundingClientRect().y

		function onScroll() {
			const position = target.getBoundingClientRect().y

			if (position < target.offsetTop - 800) {
				setIsDisplayed(true)
			}
		}

		if (currentPosition < target.offsetTop - 800) {
			setIsDisplayed(true)
		}
		document.addEventListener('scroll', onScroll)

		return () => {
			document.removeEventListener('scroll', onScroll)
		}
	}, [])

	return (
		<div className="mt-20 lg:mt-52 w-full">
			<div
				className={classnames(
					valueSphere,
					isDisplayed ? valueSphereLine : '',
					'flex relative justify-center items-center mx-auto bg-blue rounded-circle'
				)}
				id="value-position"
			>
				<span className="text-base text-secondary">VALUE</span>
			</div>
			<h2 className="mt-8 text-xl lg:text-4xl font-bold text-center">わたしたちが成長し続けるため</h2>
			<h2 className="mt-2 text-xl lg:text-4xl font-bold text-center">大事にしている価値基準</h2>
			<div className="flex flex-wrap mt-14 lg:mt-20">
				{valueData.map((value) => {
					return (
						<div key={value.name} className={valueFlame}>
							<h3 className="text-xl lg:text-2xl font-bold">{value.name}</h3>
							<span className="mt-2 text-xs text-blue">{value.sub}</span>
							<p className="mt-4 lg:mt-5 text-sm leading-loose">{value.description}</p>
						</div>
					)
				})}
			</div>
		</div>
	)
}
