import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import {
	missionBg,
	missionLinePath,
	missionLongPath,
	missionMainBg,
	missionSection,
	missionSphere,
	missionSphereLine,
} from './Mission.module.scss'

export const Mission = () => {
	const [mainPosition, setMainPosition] = useState(0)
	const [isDisplayed, setIsDisplayed] = useState(false)

	useEffect(() => {
		const pageWidth = document.body.offsetWidth
		const missionWidth = document.getElementById('mission-body').offsetWidth

		const mainBgPosition = -(pageWidth - missionWidth) / 2

		setMainPosition(mainBgPosition)
	}, [])

	useEffect(() => {
		const target = document.getElementById('mission-body')
		const currentPosition = target.getBoundingClientRect().y

		function onScroll() {
			const position = target.getBoundingClientRect().y

			if (position > 0) {
				setIsDisplayed(true)
			}
		}

		if (currentPosition > 0) {
			setIsDisplayed(true)
		}

		document.addEventListener('scroll', onScroll)

		return () => {
			document.removeEventListener('scroll', onScroll)
		}
	}, [])

	return (
		<div className="flex relative flex-col-reverse lg:flex-row justify-between mt-12 lg:mt-0 w-full" id="mission-body">
			<div
				style={{ left: mainPosition }}
				className={classnames(missionMainBg, 'absolute bg-center bg-no-repeat bg-contain rounded-r-l')}
			/>
			<div style={{ left: mainPosition }} className="absolute">
				<span className={isDisplayed ? missionLinePath : ''} />
			</div>
			<div style={{ left: mainPosition - 160 }} className="absolute">
				<span className={isDisplayed ? missionLongPath : ''} />
			</div>
			<div className={classnames(missionBg, 'lg:mt-auto bg-center bg-no-repeat bg-contain rounded-l')} />
			<div className={classnames(missionSection, 'lg:pb-20')}>
				<div
					className={classnames(
						missionSphere,
						isDisplayed ? missionSphereLine : '',
						'flex relative justify-center items-center ml-auto bg-blue rounded-circle'
					)}
				>
					<span className="text-base text-secondary">MISSION</span>
				</div>
				<h2 className="mt-8 text-2xl lg:text-4xl font-bold">外食にテクノロジーを</h2>
				<p className="mt-2 text-sm text-blue">Empower all the restaurants</p>
				<p className="mt-8 text-sm leading-8">
					外食産業の歴史は古く、私たちの生活の一部になっています。1つ1つの飲食店が数多くの人を幸せにしてきました。しかし、歴史ある業界だからこそ、長い間「あたりまえ」の事として受け継がれ、見過ごされてきたたくさんの課題が残っている事も事実です。
					<br />
					飲食店がテクノロジーを活用することで、本来持っているポテンシャルを発揮できると考えております。
					<br />
					私たちはテクノロジーの力で外食産業全体のイノベーションをリードします。
				</p>
			</div>
		</div>
	)
}
