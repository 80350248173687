import React from 'react'
import classnames from 'classnames'
import { recruitFood } from './RecruitButton.module.scss'

type Props = {
	className?: string
}

export const RecruitButton = ({ className }: Props) => {
	return (
		<div className={recruitFood}>
			<p className="hidden text-xs text-center">採用強化中</p>
			<div className={classnames(className, 'bg-center bg-no-repeat bg-contain')} />
		</div>
	)
}
